<script setup>
import { DxLoadIndicator } from 'devextreme-vue/load-indicator';
import { ref, watchEffect } from 'vue';

const props = defineProps({
  visible: { type: Boolean },
  white: { type: Boolean },
  delay: { type: Number, default: 200 },
  text: { type: String },
});
const loadIndicatorVisible = ref(false);

let timer;
watchEffect(() => {
  if (props.visible === true) {
    timer = setTimeout(() => {
      loadIndicatorVisible.value = true;
    }, props.delay);
  }
  if (props.visible === false) {
    clearTimeout(timer);
    loadIndicatorVisible.value = false;
  }
});
</script>

<template>
  <DxLoadIndicator
    :visible="loadIndicatorVisible"
    class="load-indicator"
    :class="white ? 'load-indicator-white' : ''"
  />
  <span v-if="loadIndicatorVisible">{{ text }}</span>
</template>

<style lang="scss">
@import '@/letapps-vue/css/variables.letpension';

.load-indicator {
  height: 32px;
  width: 32px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}

.load-indicator-white .dx-loadindicator-icon .dx-loadindicator-segment {
  background-color: white;
}
</style>
