import axios from 'axios';

let appConfig;

export function getAppConfig() {
  return appConfig;
}

export const initAppConfig = async () => {
  const response = await axios.get(`/app-config.json?_cb=${new Date().getTime()}`, {});
  appConfig = response.data;
  console.log(`build number: ${appConfig.buildNumber}`);
};
