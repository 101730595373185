import * as Sentry from '@sentry/vue';
import { getAppConfig } from './appConfig';

export const initSentry = async (app, router) => {
  const appConfig = getAppConfig();
  const sentryConfig = appConfig.sentry;
  if (sentryConfig.enabled) {
    Sentry.init({
      app,
      logErrors: true, // Also log to console
      trackComponents: true,
      dsn: sentryConfig.dsn,
      integrations: [
        Sentry.replayIntegration({
          networkDetailAllowUrls: [
            window.location.origin,
            'https://letapps.letpension.dk',
            'https://letapps.letpension.dk/api/brugerauthorization/check',
            getAppConfig().apiRoot,
          ],
          networkRequestHeaders: ['Cache-Control'],
          networkResponseHeaders: ['Referrer-Policy'],
        }),
        Sentry.browserTracingIntegration({
          router,
          tracingOrigins: [window.location.origin, getAppConfig().apiRoot],
        }),
      ],
      // Session Replay
      replaysSessionSampleRate: sentryConfig.tracesSampleRate,
      replaysOnErrorSampleRate: 1.0,
      tracesSampleRate: sentryConfig.tracesSampleRate,

      release: appConfig.buildNumber,
      environment: appConfig.env,
    });
    console.log('Sentry initialized');
  }
};
