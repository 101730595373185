import { reactive } from 'vue';
import * as Sentry from '@sentry/browser';
import { InteractionRequiredAuthError, PublicClientApplication } from '@azure/msal-browser';
import axios from 'axios';
import { getAppConfig } from './appConfig';

export const authStatus = {
  UNKNOWN: '',
  UNAUTHENTICATED: 'Logger ind...',
  AUTHENTICATED: 'Et øjeblik, mens vi tjekker din adgang',
  AUTHORIZED: 'authorized',
  FAILED: 'Fejlet',
  USERCHECK: 'Kontrollerer bruger',
  USERCHECK_OK: '',
  USERCHECK_UNREGISTERED: 'Bruger ikke registreret',
  USERCHECK_FAILED: 'Fejl ved kontrol af brugernavn',
  USERCHECK_NETWORK_FAILED: 'Ingen adgang til API. Check evt. netværksforbindelsen.',
  REGISTER_PENDING: 'Bekræftelsesmail er afsendt',
};

export const authState = reactive({
  status: authStatus.UNKNOWN,
  failed: false,
  account: {},
  bruger: {
    id: -1,
    rettigheder: [],
  },
  accessToken: '',
  username: '',
});

let msalInstance;

export function requireAuthentication() {
  msalInstance = new PublicClientApplication(getAppConfig().msalConfig);
  msalInstance.initialize().then(() => {
    msalInstance
      .handleRedirectPromise()
      .then(handleResponse)
      .catch((error) => {
        console.log('Hvad går galt, for at vi ender her???');
        console.log(error);
      });
  });
}

async function handleResponse() {
  const currentAccounts = msalInstance.getAllAccounts();

  if (currentAccounts.length >= 1) {
    const account = currentAccounts[0];

    authState.status = authStatus.AUTHENTICATED;
    authState.account = account;

    if (getAppConfig().sentry.enabled) {
      Sentry.setUser({
        id: account.localAccountId,
        username: account.name,
        ip_address: '{{auto}}',
      });
    }

    axios
      .get(`${getAppConfig().apiRoot}/api/brugerauthorization`)
      .then((response) => {
        authState.bruger = response.data;
        authState.status = authStatus.AUTHORIZED;
        if (getAppConfig().sentry.enabled) {
          Sentry.setUser({
            id: authState.bruger.id.toString(),
            username: authState.bruger.email,
            email: authState.bruger.email,
            ip_address: '{{auto}}',
          });
        }
      })
      .catch((err) => {
        console.error(err);
        authState.status = authStatus.FAILED;
        authState.failed = true;
      });
  } else {
    authState.status = authStatus.UNAUTHENTICATED;
  }
}

export async function signIn(username) {
  authState.username = username;
  const userIsRegistered = await checkIfUserIsRegistered(username);

  if (userIsRegistered) {
    msalInstance
      .loginRedirect({
        loginHint: username,
        scopes: ['openid', ...getAppConfig().b2cScopes],
      })
      .catch((error) => {
        console.log('login error');
        console.log(error);
      });
  }
}

export function retryLogin() {
  if (msalInstance.getAllAccounts().length > 0) {
    signOut();
  } else {
    authState.status = authStatus.UNAUTHENTICATED;
    authState.failed = false;
  }
}

export async function checkIfUserIsRegistered(username) {
  authState.status = authStatus.USERCHECK;

  const params = new URLSearchParams({
    email: username,
    _cb: new Date().getTime(),
  });
  let userExists = 0;
  let response;
  try {
    response = await fetch(`${getAppConfig().apiRoot}/api/brugerauthorization/check?${params}`, {
      params: { email: username, _cb: new Date().getTime() },
    });
    if (!response.ok) {
      authState.status = authStatus.USERCHECK_FAILED;
      authState.failed = true;
      Sentry.captureException(new Error('Check user response not OK', response));
      const responseText = await response.text();
      Sentry.captureMessage(responseText);
      console.log(responseText);
      return false;
    }
    userExists = await response.text();
  } catch (e) {
    authState.status = authStatus.USERCHECK_NETWORK_FAILED;
    authState.failed = true;
    console.error(e);
    Sentry.captureException(e);
    return false;
  }

  if (userExists > 0) {
    authState.status = authStatus.USERCHECK_OK;
    return true;
  }
  authState.status = authStatus.USERCHECK_UNREGISTERED;
  return false;
}

export function signOut() {
  // // window.localStorage.removeItem('recent_username');
  try {
    msalInstance.logoutRedirect({
      account: authState.account,
      postLogoutRedirectUri: '/',
    });
  } catch (error) {
    console.log('logout error');
    console.log(error);
  }
}

export function getToken() {
  const request = {
    scopes: [...getAppConfig().b2cScopes],
    // account: msalInstance.getAccountByHomeId(authState.account.homeAccountId)
    account: authState.account,
  };
  return (
    msalInstance
      .acquireTokenSilent(request)
      .then((response) => {
        // In case the response from B2C server has an empty accessToken field
        // throw an error to initiate token acquisition
        if (!response.accessToken || response.accessToken === '') {
          throw new InteractionRequiredAuthError();
        }
        authState.accessToken = response.accessToken;
        return response;
      })
      // eslint-disable-next-line consistent-return
      .catch((error) => {
        console.log(error);
        console.log('silent token acquisition fails. Sign out??');
        console.log('Should we only sign out if fail during login?');
        // signOut();

        if (error instanceof InteractionRequiredAuthError) {
          // fallback to interaction when silent call fails
          return msalInstance.acquireTokenRedirect(request);
        } else {
          signOut();
        }
      })
  );
}

export function hasRettighed(rettighed) {
  return authState.bruger.rettigheder.includes(rettighed);
}

export function isLetpensionBruger() {
  return authState.bruger.organisation.type === 'Letpension';
}

export function getBrugerOrganisationId() {
  return authState.bruger.organisationId;
}
