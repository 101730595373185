<template>
  <div class="scrollbox" tabindex="0">
    <div class="scrollbox-content">
      <slot />
    </div>
  </div>
</template>

<script setup></script>

<style lang="scss">
@import '@/letapps-vue/css/variables.letpension';

.scrollbox {
  height: 100%;
  overflow: auto;
  visibility: hidden;
  transition: visibility 0.2s;
}

.scrollbox-content,
.scrollbox:hover,
.scrollbox:focus {
  visibility: visible;
}

.scrollbox:hover {
  transition: visibility 0s 0.2s;
}

/* width */
::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}

//Nødvendig for at print ikke blivet "cuttet af" efter første side
@media print {
  .scrollbox {
    height: auto;
  }
}

/* Track */
::-webkit-scrollbar-track {
  background: #f8f8f8;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #dddddd;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #cccccc;
}
</style>
