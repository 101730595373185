<script setup>
const props = defineProps({
  title: { type: String },
  leftPadding: { type: Boolean, default: true },
});

function useLeftPaddingClass() {
  return props.leftPadding ? '' : 'no-left-padding';
}
</script>

<template>
  <div class="let-card" :class="useLeftPaddingClass()">
    <div v-if="title" class="let-card-title">{{ title }}</div>
    <div class="let-card-top-right">
      <slot name="top-right"></slot>
    </div>
    <div class="let-card-content">
      <slot />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/letapps-vue/css/variables.letpension.scss';

.let-card {
  padding: 30px;
  position: relative;
  font-size: 1rem;

  &.no-left-padding {
    padding-left: 0;
  }
}

.let-card-title {
  font-size: 1.2rem;
  font-weight: 600;
  padding: 0 0 8px 0;
  line-height: 1.2;
}

.let-card-top-right {
  position: absolute;
  top: 16px;
  right: 16px;
}

.let-page-content {
  flex-grow: 1;
  overflow: hidden;
}
</style>
