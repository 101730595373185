<script setup>
const props = defineProps({
  sidebredde: { type: String, default: 'normal' },
});

function getReadingAreaStyle() {
  if (props.sidebredde === 'fuld') {
    return { flex: 1 };
  } else {
    return { width: sidebreddeDefinitions[props.sidebredde] };
  }
}

const sidebreddeDefinitions = {
  smal: '800px',
  normal: '1000px',
  bred: '1200px',
  max: '1400px',
};
</script>

<template>
  <div class="flex-container">
    <div v-if="sidebredde !== 'fuld'" class="reading-area-side-spacing" />
    <div :style="getReadingAreaStyle()">
      <slot />
    </div>
    <div v-if="sidebredde !== 'fuld'" class="reading-area-side-spacing" />
  </div>
</template>

<style scoped lang="scss">
@import '@/letapps-vue/css/variables.letpension.scss';

@media screen {
  .reading-area-side-spacing {
    flex: 1;
  }
}
</style>
