<script setup lang="ts">
import { DxSelectBox } from 'devextreme-vue/select-box';

const rapportOptions = [
  { value: 'nysalg-pi', text: 'Nysalg' },
  { value: 'nysalg-pi-privat', text: 'Nysalg Privat' },
  { value: 'nysalg-pi-lts', text: 'Nysalg LTS' },
  { value: 'bestand-livrente-pi', text: 'Bestand Livrente' },
  { value: 'bestand-livrente-pi-privat', text: 'Bestand Livrente Privat' },
  { value: 'bestand-risiko-pi', text: 'Bestand Risiko' },
  { value: 'bestand-risiko-pi-privat', text: 'Bestand Risiko Privat' },
  { value: 'livrenteunderudbetaling-pi', text: 'Livrente under udbetaling' },
  { value: 'raadgiverstatistik-uge-pi', text: 'Rådgiverstatistik, uge' },
  { value: 'raadgiverstatistikdetaljer-uge-pi', text: 'Rådgiverstatistik, uge detaljer' },
  { value: 'raadgiverstatistik-maaned-pi', text: 'Rådgiverstatistik, måned' },
  { value: 'ydelsesaendringer-pi', text: 'Ydelsesændringer' },
  { value: 'skiftafpengeinstitut-pi', text: 'Skift af pengeinstitut' },
];
</script>

<template>
  <DxSelectBox :items="rapportOptions" placeholder="Rapport" displayExpr="text" valueExpr="value"
    style="margin-right: 12px" :width="180" />
</template>

<style scoped></style>
