import axios from 'axios';
import * as Sentry from '@sentry/browser';
import notify from 'devextreme/ui/notify';
import { getToken } from './auth';
import { getAppConfig } from './appConfig';

export const initAxios = async () => {
  axios.interceptors.request.use(
    async (config) => {
      if (config.meta?.anonymous === true) {
        return config;
      }
      const response = await getToken();
      if (response) {
        const token = response.accessToken;
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      console.log('ERROR getting token');
      Promise.reject(error);
    },
  );

  axios.interceptors.response.use(
    (res) => res,
    (err) => {
      let message = err.message;
      if (err.response?.data?.Message) {
        message = message + '\n' + err.response?.data?.Message;
      }
      if (err.response?.data?.InnerException?.Message) {
        message = message + '\n' + err.response?.data?.InnerException?.Message;
      }
      console.error(message);
      if (!getAppConfig().env.endsWith('prod'))
        notify(
          {
            message: message,
            height: 45,
            // width: 200,
            // minWidth: 200,
            type: 'error',
            // displayTime: 6000,
            animation: {
              show: { type: 'fade', duration: 400, from: 0, to: 1 },
              hide: { type: 'fade', duration: 40, to: 0 },
            },
          },
          { position: 'bottom center', direction: 'up-push' },
        );

      if (getAppConfig().sentry.enabled) {
        Sentry.captureException(err);
      }
      return Promise.reject(err);
    },
  );
};

export { axios };
