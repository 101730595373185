<script setup></script>

<template>
  <div class="let-toolbar">
    <slot />
    <div class="flex-justify-end let-toolbar-group">
      <slot name="end"></slot>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/letapps-vue/css/variables.letpension.scss';

.let-toolbar {
  display: flex;
  gap: 10px;
  border-bottom: 1px solid #dddddd;
  padding-bottom: 5px;
  margin-bottom: 10px;
}

@media print {
  .let-toolbar {
    display: none;
  }
}

.let-toolbar-group {
  gap: 10px;
}
</style>
