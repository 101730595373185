<script setup>
import { ref } from 'vue';
import { authState } from '../utils/auth';

defineProps({ title: String });

const pageVisible = ref(false);

setTimeout(() => {
  pageVisible.value = true;
}, 1000);
</script>

<template>
  <div v-if="pageVisible" class="loader-screen">
    <img src="../assets/letpension-logo.svg" class="logo" alt="Letpension logo" />
    <div class="title">
      <h1>{{ title }}</h1>
    </div>

    <div class="loader">
      <div class="bar1"></div>
      <div class="bar2"></div>
      <div class="bar3"></div>
    </div>
    <div v-if="authState.status !== 'unknown'" class="message">
      {{ authState.status }}
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '../css/variables.letpension';

.loader-screen {
  width: 100%;
  min-height: 100%;
  background-color: $letpension-sand;
  background-image: url(../assets/letpension-facet.svg);
  background-repeat: no-repeat;
  background-size: 6000px;
  background-position: -2700px -2700px;

  position: absolute;

  .title {
    margin-top: 150px;
    text-align: center;
  }

  .logo {
    position: fixed;
    top: 25px;
    left: 25px;
    height: 50px;
  }

  .loader {
    margin: 0 auto;
    width: 30px;
    height: 50px;
    text-align: center;
    font-size: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    z-index: 500;

    > div {
      height: 100%;
      width: 8px;
      display: inline-block;
      float: left;
      margin-left: 2px;
      animation: delay 1.8s infinite ease-in-out;
    }

    .bar1 {
      background-color: $letpension-blue;
      animation-delay: -0.3s;
    }

    .bar2 {
      background-color: $letpension-orange;
      animation-delay: -0.2s;
    }

    .bar3 {
      background-color: $letpension-green;
      animation-delay: -0.1s;
    }
  }
}

@keyframes delay {
  0%,
  40%,
  100% {
    transform: scaleY(0.05);
    -webkit-transform: scaleY(0.05);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}

.message {
  position: absolute;
  top: 60%;
  left: 50%;
  text-align: center;
  transform: translateY(-50%) translateX(-50%);
}

.errorMessage {
  background-color: $letpension-red;
  color: white;
  font-size: 1.25rem;
  font-weight: bold;
  padding: 16px;
  text-align: center;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}
</style>
