import CustomStore from 'devextreme/data/custom_store';
import axios from 'axios';
import { getAppConfig } from '@/letapps-vue/utils/appConfig';
import DataSource from 'devextreme/data/data_source';

const isNotEmpty = (value) => value !== undefined && value !== null && value !== '';

export function createDevextremeDatasource(URI, key, datasourceOptions) {
  const { apiRoot } = getAppConfig();
  const URL = `${apiRoot}${URI}`;

  const store = new CustomStore({
    key,
    byKey: (id) =>
      axios.get(`${URL}/${id}`).then((response) => {
        return response.data;
      }),
    load: (loadOptions) => {
      const params = processLoadOptions({
        ...loadOptions,
        userData: store.userData,
      });

      return axios.get(URL + params).then((response) => {
        return response.data;
      });
    },
    insert: (values) => axios.post(URL, values),
    update: (id, values) => axios.put(`${URL}/${id}`, { id, ...values }),
    remove: (id) => axios.delete(`${URL}/${id}`),
  });
  store.userData = {};

  const datasource = new DataSource({ store, ...datasourceOptions });
  datasource.save = (values) => {
    if (values[key]) {
      return datasource.store().update(values[key], values);
    } else {
      return datasource.store().insert(values);
    }
  };

  datasource.loadIfEmpty = async () => {
    if (datasource.items().length > 0) return datasource.items();
    else return datasource.load();
  };

  return datasource;
}

function processLoadOptions(loadOptions) {
  //Det ser ud til at Devextreme.AspNet.Data server-side ikke forstår searchExpr osv. parametre
  //Men den forstår filter parameteren, så vi laver søgningen om til "filter" format
  if (loadOptions.searchExpr && !loadOptions.filter) {
    loadOptions.filter = [
      loadOptions.searchExpr,
      loadOptions.searchOperation,
      loadOptions.searchValue,
    ];
    loadOptions.searchExpr = null;
    loadOptions.searchOperation = null;
    loadOptions.searchValue = null;
  }

  let params = '?';

  [
    'filter',
    'group',
    'groupSummary',
    'parentIds',
    'requireGroupCount',
    'requireTotalCount',
    'searchExpr',
    'searchOperation',
    'searchValue',
    'select',
    'sort',
    'skip',
    'take',
    'totalSummary',
    'userData',
  ].forEach((i) => {
    if (i in loadOptions && isNotEmpty(loadOptions[i])) {
      params += `${i}=${JSON.stringify(loadOptions[i])}&`;
    }
  });
  params = params.slice(0, -1);

  return params;
}

export function getJsonFromBlob(b) {
  return new Promise((resolve) => {
    const reader = new FileReader();

    reader.onload = function () {
      const json = JSON.parse(this.result);
      resolve(json);
    };
    reader.readAsText(b);
  });
}
