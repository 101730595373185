<script setup>
import DxButton from 'devextreme-vue/button';
import DxTextBox from 'devextreme-vue/text-box';
import { ref } from 'vue';
import { signIn } from '../utils/auth';

defineProps({ title: String });

const defaultUsername = window.localStorage.getItem('recent_username') || '';

const username = ref(defaultUsername);
const usernameField = ref(null);

const signInWithUsername = () => {
  window.localStorage.setItem('recent_username', username.value);
  signIn(username.value);
};

const usernameIsValid = () => {
  const emailPattern = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;
  return emailPattern.test(username.value);
};

const setFocus = (e) => {
  setTimeout(() => {
    e.component.focus();
  }, 0);
};
</script>

<template>
  <div class="login-page">
    <img src="../assets/letpension-logo.svg" class="logo" alt="Letpension logo" />
    <div>
      <div>
        <h1>{{ title }}</h1>
      </div>
      <div class="login-form">
        <DxTextBox
          :ref="usernameField"
          class="username-field"
          mode="email"
          placeholder="Log på med din mailadresse"
          v-model="username"
          @enterKey="signInWithUsername"
          @initialized="setFocus"
          valueChangeEvent="keyup"
        >
        </DxTextBox>
        <DxButton
          text="Log ind"
          @click="signInWithUsername"
          type="default"
          class="login-button"
          :disabled="!usernameIsValid()"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '../css/variables.letpension';

.login-page {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $letpension-sand;
  background-image: url(../assets/letpension-facet.svg);
  background-repeat: no-repeat;
  background-size: 6000px;
  background-position: -2700px -2700px;
  text-align: center;
  padding: 16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.logo {
  position: fixed;
  top: 25px;
  left: 25px;
  height: 50px;
}

.login-form {
  margin-top: 48px;
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
}

.login-button {
  margin-left: 10px;
}

.username-field {
  width: 350px;
}
</style>
